import React, { useState } from 'react';
import { View, Text, logger, Theme, variantProvider } from '@/app';
import { onMount } from '@codeleap/common';
import { useListings } from '@/services/api';

import { CreateProductForm } from '@/layout/Product';
import { TitleComponent } from '@/shared';

const OfferNewItem: React.FC = (props) => {
  const { id } = props;

  const {
    data: { listings, listing, listingsByQuery },
    queries: Listings,
  } = useListings();

  logger.log({ listing });

  onMount(() => {
    Listings.getDetail.send(id).then((item) => {
      Listings.getData.send(item);
    });
  });

  return (
    <>
      <TitleComponent title={`Offer New item in ${listing?.title}`} />
      <CreateProductForm isOffer offerListing={listing} />;
    </>
  );
};

export default OfferNewItem;
