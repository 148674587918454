import React, { useState } from 'react'
import {
  Button,
  View,
  Text,
  logger,
  Theme,
  TextInput,
  Select,
  variantProvider,
} from '@/app'
import { TitleComponent, ProductInfo } from '@/shared'
import { useForm, onUpdate, onMount } from '@codeleap/common'
import { offerCurrencyForm, useAppSelector, formatedDistanceDate } from '@/lib'
import { useListings, useOffers, useProfiles, useCurrencyConversion } from '@/services/api'
import { navigate } from 'gatsby'
import getSymbolFromCurrency from 'currency-symbol-map'

const OfferCurrencyItem: React.FC = ({ id }) => {
  const {
    queries: Offers,
    loading,
  } = useOffers()

  const {
    data: { listing },
    queries: Listings,
  } = useListings()

  const {
    data: { rates, timestamp },
    queries: Conversion,
  } = useCurrencyConversion()

  const { profile: myProfile } = useAppSelector((store) => store.Session)

  const [conversionRate, setConversionRate] = useState(0)

  const isSmall = Theme.hooks.down('mid')

  const form = useForm(offerCurrencyForm, {
    output: 'json',
    validateOn: 'blur',
  })

  onMount(() => {
    Listings.getDetail.send(id).then((item) => {
      Listings.getData.send(item)
    })
  })

  onUpdate(() => {
    if (myProfile?.data?.currency) {
      Conversion.query.send({
        profileCurrency,
        offerCurrency,
      })
    }
  }, [myProfile, form?.values?.exchange_for_currency])

  onUpdate(() => {
    if (rates) {
      const profileRate = rates[Object.keys(rates)[0]]
      const offerRate = rates[Object.keys(rates)[1]]

      setConversionRate(profileRate / offerRate)
    }
  }, [rates])

  const profileCurrency = myProfile?.data?.currency
  const offerCurrency = form?.values?.exchange_for_currency

  // const conversionRate = Object.values(conversion?.rates)[0] / Object.values(conversion?.rates)[1]

  logger.log({ conversionRate })

  const handleCreateOffer = () => {

    // const isValid = form.validateAll(true)
    // if (isValid) {
    //   Offers.post
    //     .send({
    //       ...form.values,
    //       target_listing: id,
    //       created_by: myProfile.id,
    //     })
    //     .then((res) => {})
    // }
    // navigate(`/account/offers`)
  }

  const currencySymbol = getSymbolFromCurrency(form.values.exchange_for_currency)

  const timestampDate = new Date(timestamp * 1000)

  const formatedDate = formatedDistanceDate(timestampDate)

  const handleChangeText = (val) => {
    const onlyValue = val.replace(/\D/g, '')
    form.setFieldValue('exchange_for_value', onlyValue)
  }

  return (
    <>
      <TitleComponent title={`Offer Currency in ${listing?.title}`} />
      <View variants={['gap:2', 'marginBottom:4']} css={styles.wrapper}>
        <View css={styles.currencyWrapper} variants={['column', 'gap:4']}>
          <Text variants={['h2']} text='Offer currency' />
          <View css={styles.inputsWrapper} variants={['gap:2']}>

            <Select
              label={'Currency'}
              placeholder='Select a Currency'
              {...form.register('exchange_for_currency')}
              arrowIconName='arrowDown'
              css={styles.select}
            />

            <TextInput
              placeholder={currencySymbol + '80.00'}
              variants={['withHover', 'relative']}
              {...form.register('exchange_for_value')}
              value={currencySymbol + ' ' + form.values.exchange_for_value}
              onChangeText={(val) => handleChangeText(val)}
              // defaultValue={currencySymbol}
            />

          </View>
          <View variants={['gap:1', 'alignCenter']}>
            <View variants={['padding:1']} css={styles.currencyTag}>
              <Text css={styles.currencyText} variants={['p2']} text={`${formatedDate}`} />
            </View>
            <Text css={styles.currencyText} variants={['bold']} text={`1 ${profileCurrency}`} />
            <Text css={styles.currencyText} text={`= ${conversionRate}${Object.keys(rates)[1]}`} />
          </View>
          <Button
            css={styles.sendButton}
            text='Send offer'
            onPress={handleCreateOffer}
          />
        </View>
        {!isSmall && (
          <ProductInfo listing={listing} profile={listing?.profile} id={id} />
        )}
      </View>
    </>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    wrapper: {
      marginTop: Theme.spacing.value(14),
      minHeight: '90vh',
    },
    currencyWrapper: {
      width: '70%',
      [Theme.media.down('mid')]: {
        width: '100%',
      },
    },
    inputsWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      [Theme.media.down('mid')]: {
        gridTemplateColumns: '1fr',
      },
    },
    select: {
      height: Theme.values.buttonAndInputHeight,
    },
    sendButton: {
      width: '30%',
      [Theme.media.down('mid')]: {
        width: '100%',
      },
    },
    currencyText: {
      color: '#B3B3B3',
    },
    currencyTag: {
      backgroundColor: Theme.colors.light.veryLightGray,
      borderRadius: Theme.borderRadius.small,
    },
  },
  true,
)

export default OfferCurrencyItem
