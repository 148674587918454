import React, { useState } from 'react'
import { onMount, onUpdate, useForm, useSearchParams, useDebounce } from '@codeleap/common'
import { View, logger, Text, TextInput, Theme, variantProvider } from '@/app'

import { AccountTabs, ProductInfo, TitleComponent } from '@/shared'
import { TabsSkeleton } from '@/skeletons'

import { useAppSelector, offerInventoryForm } from '@/lib'

import { useListings, useOffers } from '@/services/api'

const OfferInventoryItem: React.FC = ({ id }) => {
  const { profile: myProfile } = useAppSelector((store) => store.Session)

  const [selectedListings, setSelectedListings] = useState([])

  const [search, setSearch] = useState('')
  const [value] = useDebounce(search, 500)

  const {
    data: { offers },
    queries: Offers,
    loading,
  } = useOffers()

  const {
    data: { listingsItems, listingsBundles, listing },
    queries: Listings,
    loading: loadingListings,
  } = useListings()

  const [params, setParams] = useSearchParams({
    tab: 1,
    page: 1,
  })

  const isSmall = Theme.hooks.down('mid')

  const form = useForm(offerInventoryForm, {
    output: 'json',
    validateOn: 'blur',
  })

  const pageNumberToOffeset = (pageNumber: number) => {
    return (pageNumber - 1) * 10
  }

  const hasItems =
    listingsItems?.results?.length > 0 || listingsBundles?.results?.length > 0

  onMount(() => {
    Listings.getDetail.send(id).then((item) => {
      Listings.getData.send(item)
    })
  })

  onUpdate(() => {
    if (myProfile) {
      Listings.getByTypes.send({
        profile: myProfile.id,
        // offset: params.page + '0' - 10,
      })
    }
  }, [myProfile])

  onUpdate(() => {
    Listings.getByTypes.send({
      profile: myProfile?.id,
      title: params.title,
    })
  }, [params.title])

  onUpdate(() => {
    if (myProfile) {
      Listings.getByTypes.send({
        profile: myProfile.id,
        offset: pageNumberToOffeset(params.page),
      })
    }
  }, [params.page])

  onUpdate(() => {
    setParams({
      ...params,
      title: value,
    })
  }, [value])

  const handleCreateOffer = () => {
    Offers.post
      .send({
        ...form.values,
        target_listing: id,
        created_by: myProfile.id,
        exchange_for_item: selectedListings,
      })
      .then((res) => {})
  }

  const handleChangeCheckbox = (item) => {
    if (selectedListings.includes(Number(item.target.value))) {
      setSelectedListings((selectedListing) => {
        return selectedListing.filter(
          (listing) => listing !== Number(item.target.value),
        )
      })
    } else {
      setSelectedListings((selectedListing) => [
        ...selectedListing,
        Number(item.target.value),
      ])
    }
  }

  const handleChangeSearch = (e) => {
    setSearch(e.target.value)

    // setParams({
    //   ...params,
    //   title: e.target.value,
    // });
  }

  return (
    <>
      <TitleComponent title={`Offer Item in ${listing?.title}`} />
      <View
        css={styles.wrapper}
        variants={['fullWidth', 'justifySpaceBetween']}
      >
        <View css={styles.itemsWrapper} variants={['column']}>
          <View
            responsiveVariants={{
              mid: ['column'],
            }}
            variants={['justifySpaceBetween']}
          >
            <Text
              variants={['h2', 'marginBottom:2']}
              text='Offer inventory item'
            />
            <TextInput
              styles={{ wrapper: styles.searchInput, icon: styles.icon }}
              placeholder='Search'
              variants={['withHover']}
              leftIcon={{
                name: 'search',
              }}
              onChange={(e) => {
                handleChangeSearch(e)
              }}
            />
          </View>
          <TabsSkeleton ready={!loadingListings}>
            <AccountTabs
              createOffer
              tab={params.tab}
              params={params}
              setParams={setParams}
              Items={Listings}
              hasItems={hasItems}
              itemsOne={listingsItems}
              itemsTwo={listingsBundles}
              onChangeCheckbox={handleChangeCheckbox}
              onPressOffer={handleCreateOffer}
              selectedListings={selectedListings}
            />
          </TabsSkeleton>
        </View>
        <View css={[styles.infoWrapper, isSmall && styles.infoWrapperDisabled]}>
          <ProductInfo
            wrapperStyle={styles.productInfo}
            id={id}
            profile={listing?.profile}
            listing={listing}
          />
        </View>
      </View>
    </>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    wrapper: {
      marginTop: Theme.spacing.value(14),
      minHeight: '90vh',
    },
    searchInput: {
      width: '30%',
      [Theme.media.down('mid')]: {
        width: '100%',
      },
    },
    itemsWrapper: {
      width: '70%',
      [Theme.media.down('mid')]: {
        width: '100%',
      },
    },
    infoWrapper: {
      width: '25%',
    },
    infoWrapperDisabled: {
      display: 'none',
    },
    productInfo: {
      width: '100%',
    },
    offerButton: {
      width: '30%',
    },
    icon: {
      color: 'rgba(0,0,0,0.4)',
      stroke: 'rgba(0,0,0,0.4)',
    },
  },
  true,
)

export default OfferInventoryItem
