import React from 'react';
import { Button, View } from '@/app';
import { Auth, Page } from '@/shared';

import OfferNewItem from './_new-item';
import OfferInventoryItem from './_inventory';
import OfferCurrencyItem from './_currency';

import { onMount } from '@codeleap/common';
import { useAppSelector } from '@/lib';
import { useListings, useProfiles } from '@/services/api';

const OfferPage: React.FC = () => {
  const { profile } = useAppSelector((store) => store.Session);

  const {
    data: { listings, listing, listingsByQuery },
    queries: Listings,
  } = useListings();

  const {
    data: { profile: profileAuthor },
    queries: Profiles,
    loading,
  } = useProfiles();

  return (
    <Auth>
      <Page
        withRouter
        basePath='/offer'
        title='Offer'
        renderContentWrapper={({ children, menuItems, styles }) => (
          <View variants={['fullWidth']}>{children}</View>
        )}
      >
        <OfferNewItem title='New Item' path=':id/new-item' />
        <OfferInventoryItem title='Inventory' path='/:id/inventory' />
        <OfferCurrencyItem
          title='Currency'
          path='/:id/currency'
          profile={profile}
        />
      </Page>
    </Auth>
  );
};

export default OfferPage;
